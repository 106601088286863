import I18n from "i18n-js";
import * as moment from "moment";

export const getHumanDate = (src) => {
  const date = moment.utc(src)
    .locale(I18n.locale.toLowerCase());

  if (date.isBefore(moment().subtract(1, "days"))) {
    return date.format("L");
  }
  
  return date.fromNow();
}

export const getHumanDateTime = (src) => {
  const date = moment.utc(src)
    .locale(I18n.locale.toLowerCase());

  if (date.isBefore(moment().subtract(1, "year"))) {
    return date.format("MMMM Do, YYYY h:mm a");
  }
  
  return date.format("MMMM Do, h:mm a");
}