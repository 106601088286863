import React, { Fragment, useEffect, useRef, useState } from "react";

import Dropdown from "./Dropdown";

import chevronIcon from "../assets/icons/chevron-right.svg";

const MenuItem = ({ item, depthLevel }) => {

  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showDropdown && ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showDropdown]);

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  }

  const onMouseEnter = () => {
    setShowDropdown(true);
  }

  const onMouseLeave = () => {
    setShowDropdown(false);
  }

  return (
    <div
      ref={ref}
      className="menu-item-wrapper"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <li className={`menu-item ${item?.selected ? "selected" : null}`}>
        {item.submenu
          ? (
            <Fragment>
              <button
                type="button"
                className="submenu-button"
                aria-haspopup="menu"
                aria-expanded={showDropdown ? "true" : "false"}
                onClick={toggleDropdown}
              >
                <span>{item.title}</span>
                {depthLevel > 0 ? <img className="chevron" src={chevronIcon} /> : null}
              </button>
              <Dropdown
                show={showDropdown}
                submenu={item.submenu}
                depthLevel={depthLevel}
              />
            </Fragment>
          )
          : item?.url
            ? (
              <a
                className={`${item.className ? item.className : ""}`}
                href={item.url}
              >
                <span>{item.title}</span>
                {item.indicator ? item.indicator : null}
              </a>
            )
            : (
              <button
                className={`${item.className ? item.className : ""}`}
                disabled={item?.clickable === false}
                onClick={item.onClick}
              >
                <span>{item.title}</span>
                {item.indicator ? item.indicator : null}
              </button>
            )
        }
      </li>
    </div>
  );
}

export default MenuItem;
