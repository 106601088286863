import React, { Component, Fragment, useState } from "react";
import { Toaster } from "react-hot-toast";

import useRoutingState from "../hooks/useRoutingState";

import ActionButton from "../commons/ActionButton";

import PageTitle from "./components/PageTitle";
import ObjectiveSelector from "./components/ObjectiveSelector";
import SummaryTimeline from "./components/SummaryTimeline";
import ShareSummaryModal from "./components/Modal/ShareSummaryModal";

import shareIcon from "../assets/icons/share.svg";

const Summary = ({ plan, isPlanOwner }) => {
  useRoutingState();

  const [objective, setObjective] = useState(plan?.objectives[0]);
  const [showShareModal, setShowShareModal] = useState(false);

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <PageTitle title="Plan Summary" showBack />
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <div className="header">
        <span className="title">{plan?.title}</span>
        <div className="action-buttons">
          {isPlanOwner ? (
            <ActionButton
              title="Share"
              icon={shareIcon}
              onClick={() => setShowShareModal(true)}
            />
          ) : null}
          <ActionButton title="Download as PDF" />
        </div>
      </div>
      <div className="details">
        <div className="objectives">
          <span className="section-title">Objectives</span>
          <ObjectiveSelector
            objectives={plan?.objectives}
            onSelect={setObjective}
          />
        </div>
        <SummaryTimeline objective={objective} />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="pdp summary">
        <div className="container-fluid">
          {renderPageHeader()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <ShareSummaryModal
        show={showShareModal}
        onShare={() => {}}
        onCancel={() => setShowShareModal(false)}
      />
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class SummaryComponent extends Component {
  render() {
    return <Summary {...this.props} />;
  }
}