import React, { Fragment, useEffect, useRef, useState } from "react";

import IconButton from "../../../commons/IconButton";

import UpdateTaskProgressModal from "../Modal/UpdateTaskProgressModal";
import EditTaskModal from "../Modal/EditTaskModal";
import DeleteTaskConfirmation from "../Modal/DeleteTaskConfirmation";

import Shine from "../Shine";
import TaskProgress from "./TaskProgress";
import TaskHistoryItem from "./TaskHistoryItem";
import NoteEditor from "./NoteEditor";

import updateIcon from "../../../assets/icons/pdp/update-task.svg"
import editIcon from "../../../assets/icons/pdp/edit-task.svg"
import deleteIcon from "../../../assets/icons/pdp/delete-task.svg"

const TaskInfo = ({ plan, objective, task }) => {

  const [selectedTask, setSelectedTask] = useState(null);

  const [showUpdateProgressModal, setShowUpdateProgressModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const taskRef = useRef();

  useEffect(() => {
    taskRef.current = task;
    setSelectedTask(task);
  }, [task]);

  const handleShineAnimationEnd = () => {
    const task = taskRef.current;
    if (task?.index !== selectedTask?.index) {
      setSelectedTask(task);
    }
  }

  return (
    <Fragment>
      <div className="task-info">
        <div className="task-header">
          <Shine trigger={task} onAnimationEnd={handleShineAnimationEnd} />
          <div className="info">
            <span className="index">Task</span>
            <span className="title" title={selectedTask?.title}>{selectedTask?.title}</span>
          </div>
          <div className="actions">
            <TaskProgress task={selectedTask} />
            <IconButton
              icon={updateIcon}
              title="Update"
              tooltipPlacement="bottom"
              onClick={() => setShowUpdateProgressModal(true)}
            />
            <IconButton
              icon={editIcon}
              title="Edit"
              tooltipPlacement="bottom"
              onClick={() => setShowEditModal(true)}
            />
            <IconButton
              icon={deleteIcon}
              title="Delete"
              tooltipPlacement="bottom"
              onClick={() => setShowDeleteConfirmation(true)}
            />
          </div>
        </div>
        <div className="task-history">
          <Shine trigger={task} onAnimationEnd={handleShineAnimationEnd} />
          <div className="header">Task History</div>
          <div className="items">
            {selectedTask?.history?.length ? (
              selectedTask?.history?.map((item, index) => (
                <TaskHistoryItem key={index} item={item} />
              ))
            ) : (
              <div className="no-items">
                <span>Task has no history yet</span>
              </div>
            )}
          </div>
          <NoteEditor
            plan={plan}
            objective={objective}
            task={selectedTask}
          />
        </div>
      </div>
      <UpdateTaskProgressModal
        plan={plan}
        objective={objective}
        task={selectedTask}
        show={showUpdateProgressModal}
        onSave={() => setShowUpdateProgressModal(false)}
        onCancel={() => setShowUpdateProgressModal(false)}
      />
      <EditTaskModal
        operation="edit"
        plan={plan}
        objective={objective}
        task={selectedTask}
        show={showEditModal}
        onSave={() => setShowEditModal(false)}
        onCancel={() => setShowEditModal(false)}
      />
      <DeleteTaskConfirmation
        plan={plan}
        objective={objective}
        task={selectedTask}
        show={showDeleteConfirmation}
        onConfirm={() => setShowDeleteConfirmation(false)}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
    </Fragment>
  );
}

export default TaskInfo;
