import React, { useState } from "react";
import toast from "react-hot-toast";
import I18n from "i18n-js";
import _ from "lodash";

import InternalModal from "../../../commons/InternalModal";
import EmailsInput from "../../../commons/EmailsInput";
import Input from "../../../commons/Input";
import Textarea from "../../../commons/Textarea";
import LoadingButton from "../../../commons/LoadingButton";

const ShareSummaryModal = ({ show, onCancel, onShare }) => {

  const [loading, setLoading] = useState(false);

  const [emails, setEmails] = useState([]);
  const [emailsError, setEmailsError] = useState("");
  const [subject, setSubject] = useState(I18n.t("email.pdp.share_summary.subject"));
  const [message, setMessage] = useState("");

  const reset = () => {
    setLoading(false);
    setEmails([]);
    setEmailsError("");
    setSubject(I18n.t("email.pdp.share_summary.subject"));
    setMessage("");
  }

  const handleEmailsValueAdded = (email) => {
    setEmails(emails => [...emails, email]);
    setEmailsError("");
  }

  const handleEmailsValueRemoved = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  }

  const handleEmailsChange = () => {
    if (emailsError !== "") setEmailsError("");
  }

  const handleSubjectChange = (event) => {
    event.preventDefault();
    setSubject(event.target.value);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const handleSendClick = () => {
    if (_.isEmpty(emails)) {
      setEmailsError(I18n.t("pdp.modals.share.inputs.emails.errors.invalid_value"));
      return;
    }

    //shareNote();
  }

  const handleCancelClick = (event) => {
    event.preventDefault();
    reset();
    onCancel && onCancel();
  }

  const renderModal = () => {
    return (
      <div className="share-summary-modal">
        <div className="title">{I18n.t("pdp.modals.share.title")}</div>
        <EmailsInput
          label={I18n.t("pdp.modals.share.inputs.emails.label")}
          placeholder={I18n.t("pdp.modals.share.inputs.emails.placeholder")}
          values={emails}
          error={emailsError}
          onValueAdded={handleEmailsValueAdded}
          onValueRemoved={handleEmailsValueRemoved}
          onChange={handleEmailsChange}
        />
        <Input
          label={I18n.t("pdp.modals.share.inputs.subject.label")}
          placeholder={I18n.t("pdp.modals.share.inputs.subject.placeholder")}
          value={subject}
          onChange={handleSubjectChange}
        />
        <Textarea
          label={I18n.t("pdp.modals.share.inputs.message.label")}
          placeholder={I18n.t("pdp.modals.share.inputs.message.placeholder")}
          rows={3}
          value={message}
          onChange={handleMessageChange}
        />
        <div className="buttons">
          <button
            className="cancel-btn"
            disabled={loading}
            onClick={handleCancelClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <LoadingButton
            filled
            loading={loading}
            className="send-btn"
            title={I18n.t("pdp.modals.share.buttons.send.title")}
            onClick={handleSendClick}
          />
        </div>
      </div>
    );
  }

  return (
    <InternalModal
      type="share-pdp-summary"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default ShareSummaryModal;
