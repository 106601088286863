import React, { useState, useEffect } from "react";
import { useLocation } from "react-use";
import I18n from "i18n-js";
import _ from "lodash";

import Selector from "./Selector";
import StatusIndicator from "./StatusIndicator";

const PlanSelector = ({ team, plans }) => {
  
  const { pathname } = useLocation();

  const [plan, setPlan] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const index = pathname.lastIndexOf("pdp/") + 4;
    const planUuid = pathname.substring(index).split("?")[0].replace("/", "");

    const plan = plans.find(plan => planUuid === plan?.development_plan?.uuid);
    if (plan) {
      setPlan(plan);
    }

  }, [plans, pathname]);

  useEffect(() => {
    const loggedUserPlan = plans.find(plan => plan?.logged_user);

    let items = plans.filter(plan => !plan?.logged_user);
    items = _.sortBy(items, ["first_name", "last_name"])
      .map(item => {
        const url = getPlanUrl(item);
        const isDraft = item?.development_plan?.status === "draft";
        return {
          title: getUserName(item),
          url: isDraft ? null : url,
          clickable: Boolean(url) && !isDraft,
          selected: item?.development_plan?.uuid === plan?.development_plan?.uuid,
          indicator: (
            <StatusIndicator
              status={url ? item?.development_plan?.status : "no_plan"}
            />
          )
        };
      });

    const url = getPlanUrl(loggedUserPlan);
    items.unshift({
      title: I18n.t("pdp.plan_selector.my_plan"),
      url: url,
      clickable: Boolean(url),
      selected: loggedUserPlan?.development_plan?.uuid === plan?.development_plan?.uuid,
      indicator: (
        <StatusIndicator
          status={url ? loggedUserPlan?.development_plan?.status : "no_plan"}
        />
      )
    });

    setItems(items);
  }, [plans, plan]);

  const getUserName = (plan) => {
    const lastName = plan?.last_name ? plan?.last_name?.substr(0, 1) + "." : "";
    return plan?.first_name ? `${plan?.first_name} ${lastName}` : "";
  }

  const getPlanUrl = (plan) => {
    if (!plan?.development_plan?.uuid) { return null; }
    return `/teams/${team.uuid}/pdp/${plan?.development_plan?.uuid}?locale=${I18n.locale}`;
  }

  return (
    <Selector
      className="plan-selector"
      title={plan?.logged_user ? I18n.t("pdp.plan_selector.my_plan") : getUserName(plan)}
      items={items}
    />
  );
}

export default PlanSelector;
