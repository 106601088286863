import React from "react";

import useTaskState from "../../../hooks/useTaskState";

import Tabs from "./Tabs";
import ObjectiveInfo from "./ObjectiveInfo";
import TaskInfo from "./TaskInfo";

const ObjectivesDetails = ({ plan, objectives, selectedObjective, onObjectiveSelect }) => {

  const { task, setTask, loading } = useTaskState(selectedObjective?.tasks);

  return (
    <div className="objectives-details">
      <Tabs
        selectedObjective={selectedObjective}
        objectives={objectives}
        onTabSelect={onObjectiveSelect}
      />
      <div className="details">
        <ObjectiveInfo
          plan={plan}
          objective={selectedObjective}
          selectedTask={task}
          onTaskSelect={setTask}
        />
        <TaskInfo
          plan={plan}
          objective={selectedObjective}
          task={task}
        />
      </div>
    </div>
  );
}

export default ObjectivesDetails;
