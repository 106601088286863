import React from "react";

import Timestep from "./Timestep";
import SimpleTimestep from "./SimpleTimestep";
import EndIndicator from "./EndIndicator";

const SummaryTimeline = ({ objective }) => (
  <div className="summary-timeline">
    <SimpleTimestep title="Objective Start" date={objective?.started_at} />
    {objective?.tasks?.map((task, index) => (
      <Timestep key={index} task={task} />
    ))}
    <EndIndicator />
    <div className="line" />
  </div>
);

export default SummaryTimeline;
